<template>
  <div
    :class="$route.name == 'Signin' || page != '' ? 'background-default' : ''"
    v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div :class="$route.name != 'Signin' ? '' : ''">
      <div class="backgound-top">
        <div
          :class="$route.name != 'Signin' ? '' : 'text-center'"
          style="width: 150px"
          class="mg-x-auto pd-t-3"
        >
          <router-link
            to="/"
            :class="
              $route.name == 'Signin' || page != ''
                ? 'pd-t-5 d-block bg-white pd-x-6 mg-x-auto'
                : ''
            "
          >
            <img src="@/assets/logo.svg" alt="" class="mw-100" />
          </router-link>
        </div>
        <el-row v-if="$route.name == 'Signin' || page != ''">
          <el-col :span="24">
            <h3 class="text-center text-primary hidden-sm-and-down mg-y-5">
              EXAC เติมเต็มความเป็นเลิศส่งออกไทยเติบโตไกลอย่างยั่งยืน
            </h3>
            <h3
              class="
                text-center text-primary
                hidden-md-and-up
                mg-b-7 mg-t-5
                font-22
              "
            >
              EXAC เติมเต็มความเป็นเลิศส่งออกไทย
              <br />เติบโตไกลอย่างยั่งยืน
            </h3>
          </el-col>
          <el-col :span="24" class="pd-t-5 text-center top-logo">
            <div class="is-flex ai-center js-center flex-warp">
              <img src="@/assets/partner/16129835921.EXIM.svg" alt="EXIM" />
              <img src="@/assets/partner/16129835922.DITP.svg" alt="DITP" />
              <img src="@/assets/partner/16129835933.OSMEP.svg" alt="OSMEP" />
              <img src="@/assets/partner/16129835934.SET.svg" alt="SET" />
              <img
                src="@/assets/partner/16131231005.Smebank.svg"
                alt="Smebank"
              />
              <img src="@/assets/partner/16129835946.TNSC.svg" alt="TNSC" />
              <img src="@/assets/partner/16131231007.TCC.svg" alt="TCC" />
              <img src="@/assets/partner/16131231008.FTI.svg" alt="FTI" />
            </div>
            <!-- <div class="hidden-md-and-up">
            <img
              src="@/assets/partner-mobile.png"
              alt="partner exim"
              class="mw-100"
            />
            <img
              src="@/assets/partner-mobile2.png"
              alt="partner exim"
              class="mw-100"
            />
          </div> -->
          </el-col>
        </el-row>
      </div>
      <div
        class="bg-round"
        :class="$route.name == 'Signin' || page != '' ? '' : 'd-none'"
      >
        <div></div>
      </div>
      <div>
        <slot />
      </div>

      <div :class="[{ 'border-footer': page == '' }, `${footerClass}`]">
        © สงวนลิขสิทธิ์ พ.ศ.
        {{ new Date().getFullYear() + 543 }}
        ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย
      </div>
    </div>
    <Cookie :pattern="$route.name == 'Signin' || page != '' ? 1 : 2" />
  </div>
</template>

<script>
import Cookie from "@/components/auth/Cookie";
export default {
  components: {
    Cookie
  },
  mounted() {
    setTimeout(() => {
      this.fullscreenLoading = false;
    }, 300);
  },
  data() {
    return {
      fullscreenLoading: true
    };
  },
  props: {
    page: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default() {
        return "grey";
      }
    },
    footerClass: {
      type: String,
      default() {
        return "text-center footer font-18 text-white pd-y-6 bg-primary";
      }
    }
  }
};
</script>

<style scoped>
*,
*:before,
*:after {
  box-sizing: unset;
}
</style>
